
import gptModule from '@/store/modules/gptModule';
import { getComponent } from '@/utils/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    // GpViewSimilar: () => getComponent("gpt/GpViewSimilar"),
  },
})
export default class GpViewSimilars extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  activeTab = '';

  get similars() {
    const similars: any = gptModule.seeSimilars ? gptModule.seeSimilars : {};
    return similars.map((comment) => {
      return {
        isBestSolution: comment.best,
        title: comment.category,
        date: new Date(comment.date).toDateString(),
        description: comment.description,
        body: comment.body,
        well: comment.nodeid,
        resolution: comment.resolution,
        category: comment.category,
        prePost: comment.pre_post ? Math.round(comment.pre_post) : null,
        hasViewed: false,
      };
    });
  }

  currentSimilarIndex = 0;

  hasPreviousCard() {
    return this.currentSimilarIndex > 0;
  }

  // Add a method to handle scrolling to the previous item
  scrollToPreviousItem() {
    const container: any = this.$refs.similarContainer;
    if (!container) return;

    // Calculate the width of a single item (assuming all items have the same width)
    const itemWidth = container.clientWidth;

    // Calculate the target scroll position for the previous item
    const previousScrollPosition = Math.max(
      (this.currentSimilarIndex - 1) * itemWidth,
      0 // Make sure we don't scroll beyond the first item
    );

    // Use smooth scrolling for a nicer user experience
    container.scrollTo({
      left: previousScrollPosition,
      behavior: 'smooth',
    });

    // Update the currentSimilarIndex to reflect the newly displayed item
    this.currentSimilarIndex--;
  }

  // Add a method to handle scrolling to the next item
  scrollToNextItem() {
    const container: any = this.$refs.similarContainer;
    if (!container) return;

    // Calculate the width of a single item (assuming all items have the same width)
    const itemWidth = container.clientWidth - 25;

    // Calculate the total scroll width (total number of items * item width)
    const totalScrollWidth = this.similars.length * itemWidth;

    // Calculate the target scroll position for the next item
    const nextScrollPosition = Math.min(
      (this.currentSimilarIndex + 1) * itemWidth,
      totalScrollWidth - container.clientWidth // Make sure we don't scroll beyond the last item
    );

    // Use smooth scrolling for a nicer user experience
    container.scrollTo({
      left: nextScrollPosition,
      behavior: 'smooth',
    });

    // Update the currentSimilarIndex to reflect the newly displayed item
    this.currentSimilarIndex++;
    this.similars[this.currentSimilarIndex].hasViewed = true;
  }

  // similars = [
  //   {
  //     id: '3',
  //     title: 'LOW SECTION ISSUE',
  //     description:
  //       'Recurring flow impediments, such as scaling, wax deposition, or sand accumulation, can hinder fluid movement in the lower section of the wellbore. These obstructions restrict fluid flow, leading to decreased productivity and increased operational challenges.',
  //     resolution:
  //       'Took targeted measures to fix the low section issue in the well. This included well-stimulation, cleaning, optimization, and chemical treatments. Optimal flow was restored, and performance was improved.',
  //     date: 'April 31, 2023',
  //     well: 'FRANZ THOMAN OHIO 1H',
  //     fixedBy: 'Duda Zacaro',
  //     isBestSolution: true,
  //   },
  //   {
  //     id: '2',
  //     title: 'LOW SECTION ISSUE',
  //     description:
  //       'Recurring flow impediments, such as scaling, wax deposition, or sand accumulation, can hinder fluid movement in the lower section of the wellbore. These obstructions restrict fluid flow, leading to decreased productivity and increased operational challenges.',
  //     resolution:
  //       'Took targeted measures to fix the low section issue in the well. This included well-stimulation, cleaning, optimization, and chemical treatments. Optimal flow was restored, and performance was improved.',
  //     date: 'April 31, 2023',
  //     well: 'FRANZ THOMAN OHIO 1H',
  //     fixedBy: 'Duda Zacaro',
  //     isBestSolution: false,
  //   },
  // ];

  // Add a method to handle the "Got it" button click

  isLastStep() {
    return this.currentSimilarIndex === this.similars.length - 1;
  }

  handleGotIt() {
    if (this.isLastStep()) {
      // Emit the 'close' event when the "Got it" button is clicked and it's the last step
      this.$emit('close');
    } else {
      // Scroll to the next item if it's not the last step
      this.scrollToNextItem();
    }
  }

  handleScroll() {
    const container: any = this.$refs.similarContainer;
    if (!container) return;

    // Calculate the width of a single item (assuming all items have the same width)
    const itemWidth = container.clientWidth - 100;

    // Calculate the current scroll position
    const currentScrollPosition = container.scrollLeft;

    // Calculate the current similar item index based on the scroll position
    const currentIndex = Math.floor(currentScrollPosition / itemWidth);

    // Update the currentSimilarIndex to reflect the newly displayed item
    this.currentSimilarIndex = currentIndex;
    this.similars[this.currentSimilarIndex].hasViewed = true;
  }

  async created() {
    // this.activeTab = this.similars[1].id;
  }
}
